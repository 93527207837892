"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var moment = require("moment");
var shared_1 = require("../../../shared");
var core_2 = require("@ngx-translate/core");
var dispatch_service_1 = require("../../dispatch.service");
var preference_service_1 = require("../../../preferences/preference.service");
var assignment_service_1 = require("../../../assignments/assignment.service");
var job_service_1 = require("../../../jobs/job.service");
var job_event_service_1 = require("../../../job-events/job-event.service");
var tag_service_1 = require("../../../tags/tag.service");
var driver_service_1 = require("../../../drivers/driver.service");
var truck_service_1 = require("../../../trucks/truck.service");
var truck_type_service_1 = require("../../../trucks/truck-type.service");
var dialogs_1 = require("../../../shared/dialogs");
var move_assignments_dialog_component_1 = require("../../../assignments/move-assignments/move-assignments-dialog.component");
var auto_assign_conflict_dialog_component_1 = require("../../../assignments/auto-assign-conflict-dialog.component");
var edit_job_event_dialog_component_1 = require("../../../job-events/edit-job-event-dialog.component");
var cancel_job_dialog_component_1 = require("../../../jobs/cancel-job-dialog.component");
var add_collaborators_dialog_component_1 = require("../../../collaborators/add-collaborators/add-collaborators-dialog.component");
var edit_collaborators_dialog_component_1 = require("../../../collaborators/edit-collaborators/edit-collaborators-dialog.component");
var send_collaborator_notes_dialog_component_1 = require("../../../collaborators/send-collaborator-notes/send-collaborator-notes-dialog.component");
var custom_field_service_1 = require("../../../custom-fields/custom-field.service");
var custom_field_1 = require("../../../custom-fields/custom-field");
var filter_option_1 = require("../../../shared/filters-panel/filter-option");
var edit_job_dialog_component_1 = require("../../../jobs/edit-job-dialog.component");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var DispatchScheduleTableComponent = /** @class */ (function () {
    function DispatchScheduleTableComponent(router, dialog, authenticationService, translateService, preferenceService, jobService, jobEventService, assignmentService, truckTypeService, tagService, dispatchService, driverService, customFieldService) {
        this.router = router;
        this.dialog = dialog;
        this.authenticationService = authenticationService;
        this.translateService = translateService;
        this.preferenceService = preferenceService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.assignmentService = assignmentService;
        this.truckTypeService = truckTypeService;
        this.tagService = tagService;
        this.dispatchService = dispatchService;
        this.driverService = driverService;
        this.customFieldService = customFieldService;
        this.errors = new core_1.EventEmitter();
        this.jobEventLoadingProgress = 0;
        this.driverLoadingProgress = 0;
        this.assignmentLoadingProgress = 0;
        this.shareLoadingProgress = 0;
        this.loadTypeOptions = [];
        this.orderTypeOptions = [
            { id: 'tons', name: 'Tons' },
            { id: 'tonnes', name: 'Tonnes' },
            { id: 'metric-tons', name: 'Metric Tons' },
            { id: 'loads', name: 'Loads' },
            { id: 'lbs', name: 'Pounds' },
            { id: 'kgs', name: 'Kilograms' },
            { id: 'cuyds', name: 'Cubic Yards' },
            { id: 'bushels', name: 'Bushels' },
            { id: 'bags', name: 'Bags' }
        ];
        this.truckTypes = [];
        this.truckTypesConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false,
            multiselect: true,
            deselectOption: 'Any Type'
        };
        this.tags = [];
        this.tagsConfig = {
            nameProperty: 'name',
            loadingOptions: false,
            multiselect: true,
            deselectOption: 'All Markets'
        };
        this.jobEventAppliedFilters = [];
        this.selectCarrier = new core_1.EventEmitter();
        this.searchCarriers = new core_1.EventEmitter();
        this.dropdownNextPage = new core_1.EventEmitter();
        this.updatePreference = new core_1.EventEmitter();
        this.trucksDropdownConfig = {
            small: true,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'ticketName',
            service: truck_service_1.TruckService,
            query: { ordering: 'name' }
        };
        this.initPrefs = false;
        this.jobEventsPreferenceKey = 'table-preferences[DispatchScheduleTableComponent-JobEvents]';
        this.driversPreferenceKey = 'table-preferences[DispatchScheduleTableComponent-Drivers]';
        this.jobEventUpdates = false;
        this.availableJobEventsColumns = [
            { key: 'select' },
            { key: 'updates' },
            { key: 'job', title: this.translateService.instant('Job'), sortable: true, sortBy: 'name' },
            { key: 'status', title: this.translateService.instant('Status'), sortable: true, sortBy: 'status' },
            { key: 'customer-name', title: this.translateService.instant('Customer'), sortable: true, sortBy: 'customer__name' },
            { key: 'order-number', title: this.translateService.instant('Order #'), sortable: true, sortBy: 'order_number_sort' },
            { key: 'job-number', title: this.translateService.instant('Job #'), sortable: true, sortBy: 'job_number' },
            { key: 'start-time', title: this.translateService.instant('Start Time'), sortable: true, sortBy: 'shift_1_Start' },
            { key: 'end-time', title: this.translateService.instant('End Time'), sortable: true, sortBy: 'shift_1_End' },
            { key: 'interval', title: this.translateService.instant('Stagger (Minutes)'), sortable: false },
            { key: 'start-location', title: this.translateService.instant('Loading Location'), sortable: true, sortBy: 'start_location_name' },
            { key: 'end-location', title: this.translateService.instant('Unloading Location'), sortable: false, sortBy: 'end_location_name' },
            { key: 'material', title: this.translateService.instant('Material'), sortable: true, sortBy: 'material' },
            { key: 'truck-type', title: this.translateService.instant('Truck Type'), sortable: true, sortBy: 'truck_types' },
            { key: 'amount-ordered', title: this.translateService.instant('Ordered Amount'), sortable: true, sortBy: 'daily_delivery_target' },
            { key: 'total-ordered', title: this.translateService.instant('Total Ordered'), sortable: true, sortBy: 'job_amount_needed' },
            { key: 'trucks-requested', title: this.translateService.instant('Trucks Requested'), sortable: true, sortBy: 'num_trucks' },
            { key: 'trucks-remaining', title: this.translateService.instant('Trucks Remaining'), sortable: false },
            { key: 'truck-tons', title: this.translateService.instant('Truck Tons'), sortable: false },
            { key: 'loads-assigned', title: this.translateService.instant('Assigned Loads'), sortable: true, sortBy: 'assigned_loads' },
            { key: 'loads-remaining', title: this.translateService.instant('Remaining Loads'), sortable: false },
            { key: 'tons-assigned', title: this.translateService.instant('Assigned Tons'), sortable: false },
            { key: 'tons-remaining', title: this.translateService.instant('Remaining Tons'), sortable: false },
            { key: 'job-notes', title: this.translateService.instant('Job Notes'), sortable: false },
            { key: 'share-notes', title: this.translateService.instant('Share Notes'), sortable: false },
            { key: 'internal-notes', title: this.translateService.instant('Internal Notes'), sortable: false },
            { key: 'assigned-trucks', title: this.translateService.instant('Assigned Trucks'), sortable: false },
            { key: 'actions', title: this.translateService.instant('Actions'), sortable: false }
        ];
        this.displayedJobEventColumns = [];
        this.loadsOrderedDefaultColumns = [
            'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
            'start-time', 'end-time', 'interval', 'start-location',
            'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
            'trucks-requested', 'trucks-remaining', 'loads-assigned', 'internal-notes',
            'loads-remaining', 'job-notes', 'share-notes', 'assigned-trucks',
            'actions'
        ];
        this.tonsOrderedDefaultColumns = [
            'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
            'start-time', 'end-time', 'interval', 'start-location',
            'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
            'trucks-requested', 'trucks-remaining', 'truck-tons',
            'loads-assigned', 'internal-notes', 'tons-assigned', 'tons-remaining',
            'job-notes', 'share-notes', 'assigned-trucks', 'actions'
        ];
        this.trucksRequestedDefaultColumns = [
            'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
            'start-time', 'end-time', 'interval', 'start-location',
            'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
            'trucks-requested', 'trucks-remaining', 'internal-notes', 'job-notes', 'share-notes',
            'assigned-trucks', 'actions'
        ];
        this.truckTypeRequestedDefaultColumns = [
            'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
            'start-time', 'end-time', 'interval', 'start-location',
            'end-location', 'material', 'truck-type', 'amount-ordered', 'internal-notes', 'total-ordered',
            'trucks-requested', 'trucks-remaining', 'job-notes', 'share-notes',
            'assigned-trucks', 'actions'
        ];
        this.availableDriverColumns = [
            { key: 'select' },
            { key: 'driver', title: this.translateService.instant('Driver'), sortable: true, sortBy: 'name' },
            { key: 'jobs', title: this.translateService.instant('Jobs'), sortable: false },
            { key: 'assigned-loads', title: this.translateService.instant('Assigned Loads'), sortable: true, sortBy: 'assignments__length' },
            { key: 'actions', title: this.translateService.instant('Actions'), sortable: false }
        ];
        this.displayedDriverColumns = [];
        this.driverDefaultColumns = ['select', 'driver', 'jobs', 'assigned-loads', 'actions'];
        this.assignmentOptions = [
            'Edit Job',
            'Clone Job',
            'Edit Job Days',
            'Edit Day Details',
            'Auto-Assign Previous Trucks & Drivers',
            'Move to a New Day',
            'Copy to a New Day',
            'Remove Assignments',
            'Add Collaborators',
            'Cancel Job'
        ];
        this.shareOptions = [
            'Edit Collaborators',
            'Send Notes to Collaborators',
            'Edit Day Details',
            'Cancel Job'
        ];
        this.driverAssignmentOptions = [
            'Remove Assignments'
        ];
        this.multipleActionDropdownOptions = [
            { name: 'Cancel Jobs', action: 'cancel', link: false }
        ];
        this.activeStatsType = 'loads';
        this.driverQuery = new core_1.EventEmitter();
        this.updateStats = new core_1.EventEmitter();
        this.driverPageSize = 100;
        this.jobEventPageSize = 100;
        this.openDriverPanel = new core_1.EventEmitter();
        this.openJobEventPanel = new core_1.EventEmitter();
        this.getJobEvents = new core_1.EventEmitter();
        this.getAssignments = new core_1.EventEmitter();
        this.getShares = new core_1.EventEmitter();
        this.assignSelectedDrivers = new core_1.EventEmitter();
        this.assignSelectedJobEvents = new core_1.EventEmitter();
        this.assignSelectedConnections = new core_1.EventEmitter();
        this.drivers = [];
        this.displayedDrivers = [];
        this.jobEvents = [];
        this.displayedJobEvents = [];
        this.selectedConnections = [];
        this.allSelected = false;
        this.selectedJobEventsValue = [];
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.selectedJobEventsChange = new core_1.EventEmitter();
        this.selectedDriversValue = [];
        this.selectedDriversChange = new core_1.EventEmitter();
        this.jobEventsDataSource = new material_1.MatTableDataSource([]);
        this.driversDataSource = new material_1.MatTableDataSource([]);
    }
    Object.defineProperty(DispatchScheduleTableComponent.prototype, "selectedJobEvents", {
        get: function () { return this.selectedJobEventsValue; },
        set: function (data) {
            this.selectedJobEventsValue = data;
            this.selectedJobEventsChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DispatchScheduleTableComponent.prototype, "selectedDrivers", {
        get: function () { return this.selectedDriversValue; },
        set: function (data) {
            this.selectedDriversValue = data;
            this.selectedDriversChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.selectLoadType(this.activeLoadType);
        this.getCustomFields();
        this.getPreferences();
        this.getTruckTypes({ page_size: 100 });
        this.getTags();
        if (this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
            setTimeout(function () { _this.startJobEventsTimer(); }, 60000);
        }
    };
    DispatchScheduleTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.activeLoadType && changes.activeLoadType.currentValue) {
            this.selectLoadType(changes.activeLoadType.currentValue);
        }
        if (changes.view && changes.view.currentValue) {
            if (changes.view.currentValue === 'jobEvents') {
                this.loadTypeOptions = ['Loads Ordered', 'Tons Ordered', 'Trucks Requested', 'Truck Type Requested'];
            }
            else if (changes.view.currentValue === 'drivers') {
                this.loadTypeOptions = ['Loads Ordered', 'Tons Ordered', 'Trucks Requested'];
                if (this.activeLoadType === 'Truck Type Requested') {
                    this.activeLoadType = 'Loads Ordered';
                }
            }
            this.allSelected = false;
            this.selectLoadType(this.activeLoadType);
            this.getPreferences();
        }
        if (changes.drivers && changes.drivers.currentValue) {
            this.displayedDrivers = changes.drivers.currentValue.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
            this.searchDrivers(this.driverSearch);
        }
        if (changes.jobEvents && changes.jobEvents.currentValue) {
            this.displayedJobEvents = changes.jobEvents.currentValue;
            this.searchJobEvents(this.jobEventSearch);
            this.applyTagsAndSetTableData();
            this.checkIfJobEventsHaveUpdates();
        }
    };
    DispatchScheduleTableComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventsTimerSub) {
            try {
                this.jobEventsTimerSub.unsubscribe();
                this.jobEventsTimer = null;
            }
            catch (e) {
                this.jobEventsTimerSub = null;
                this.jobEventsTimer = null;
            }
        }
    };
    DispatchScheduleTableComponent.prototype.startJobEventsTimer = function () {
        var _this = this;
        this.jobEventsTimer = rxjs_1.timer(0, 60000);
        if (this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
            this.jobEventsTimerSub = this.jobEventsTimer.subscribe(function (t) {
                var last_modified__gt = moment().subtract(1, 'minutes').toISOString();
                _this.getJobEvents.emit({ last_modified__gt: last_modified__gt });
            });
        }
    };
    DispatchScheduleTableComponent.prototype.getAssignmentOptions = function (jobEvent) {
        if (!jobEvent.canEdit) {
            return this.assignmentOptions.filter(function (option) { return !['Edit Job', 'Edit Job Days', 'Cancel Job'].includes(option); });
        }
        if (jobEvent.cancelled) {
            return this.assignmentOptions.filter(function (option) { return option !== 'Cancel Job'; });
        }
        return this.assignmentOptions;
    };
    /**
     * Sums up the total of trucks requested on all shares for a jobEvent
     *
     * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
     * @return {number} The amount of requested trucks
     */
    DispatchScheduleTableComponent.prototype.getSharedRequestedAmount = function (dispatchJobEvent) {
        return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
            dispatchJobEvent.shares.map(function (s) { return (s.numTrucks); }).reduce(function (a, b) { return (a + b); }) :
            0;
    };
    /**
     * Sums up the total of trucks confirmed on all shares for a jobEvent
     *
     * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
     * @return {number} The amount of confirmed trucks
     */
    DispatchScheduleTableComponent.prototype.getSharedConfirmedAmount = function (dispatchJobEvent) {
        return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
            dispatchJobEvent.shares.map(function (s) { return (s.confirmedTrucks); }).reduce(function (a, b) { return (a + b); }) :
            0;
    };
    /**
     * Sums up the total of trucks dispatched on all shares for a jobEvent
     *
     * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
     * @return {number} The amount of dispatched trucks
     */
    DispatchScheduleTableComponent.prototype.getSharedDispatchedAmount = function (dispatchJobEvent) {
        return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
            dispatchJobEvent.shares.map(function (s) { return (s.dispatched); }).reduce(function (a, b) { return (a + b); }) :
            0;
    };
    DispatchScheduleTableComponent.prototype.getRemainingLoadCount = function (a, b) {
        return Number(a) - Number(b);
    };
    DispatchScheduleTableComponent.prototype.getDispatchedCount = function (assignments) {
        return assignments ? assignments.filter(function (a) { return (a.dispatched); }).length : 0;
    };
    DispatchScheduleTableComponent.prototype.getAssignedTons = function (a, b) {
        return Number(a) * Number(b);
    };
    DispatchScheduleTableComponent.prototype.dispatchAvailable = function () {
        var available = false;
        if (this.jobEvents) {
            this.jobEvents.forEach(function (jobEvent) {
                if (!available && jobEvent.selected && jobEvent.assignments.length > 0) {
                    available = true;
                }
            });
        }
        return available;
    };
    DispatchScheduleTableComponent.prototype.getUndispatchedIds = function (jobEvent) {
        var undispatchedIds = [];
        jobEvent.assignments.forEach(function (assignment) {
            if (!assignment.dispatched) {
                undispatchedIds.push(assignment.driver.id);
            }
        });
        return undispatchedIds;
    };
    DispatchScheduleTableComponent.prototype.getUnassignedIds = function (row, selectedIds) {
        var unassignedIds = [];
        selectedIds.forEach(function (id) {
            if (row.assignments.findIndex(function (assignment) { return (assignment.driver.id === id); }) === -1) {
                unassignedIds.push(id);
            }
        });
        return unassignedIds;
    };
    DispatchScheduleTableComponent.prototype.selectLoadType = function (option) {
        if (this.activeLoadType !== option) {
            this.activeLoadType = option;
            this.updatePreference.emit({ activeLoadType: this.activeLoadType });
        }
        if (this.view === 'collaborators') {
            this.displayedJobEventColumns = [
                'job', 'status', 'customer-name', 'trucks-requested', 'trucks-assigned',
                'trucks-dispatched', 'collaborator-actions', 'actions'
            ];
        }
        else if (this.view === 'drivers') {
            this.displayedDriverColumns = this.driverDefaultColumns;
        }
        else {
            switch (option) {
                case 'Loads Ordered':
                    this.displayedJobEventColumns = this.loadsOrderedDefaultColumns;
                    break;
                case 'Tons Ordered':
                    this.displayedJobEventColumns = this.tonsOrderedDefaultColumns;
                    break;
                case 'Trucks Requested':
                    this.displayedJobEventColumns = this.trucksRequestedDefaultColumns;
                    break;
                case 'Truck Type Requested':
                    this.displayedJobEventColumns = this.displayedJobEventColumns;
                    break;
            }
        }
        this.parsePreferences();
    };
    DispatchScheduleTableComponent.prototype.selectStatsType = function () {
        var _this = this;
        var activeTypes = Object.keys(this.loadStats.ordered).filter(function (key) { return (_this.loadStats.ordered[key] > 0 || key === 'loads'); });
        var currentSelectedTypeIndex = activeTypes.findIndex(function (t) { return (t === _this.activeStatsType); });
        this.activeStatsType = activeTypes[currentSelectedTypeIndex + 1 < activeTypes.length ?
            currentSelectedTypeIndex + 1 : 0];
    };
    DispatchScheduleTableComponent.prototype.editJobEvent = function (row) {
        var _this = this;
        this.jobEvents.map(function (jobEvent) {
            row.selected = (jobEvent.id === row.id);
            return row;
        });
        this.selectedDrivers = [];
        row.assignments.forEach(function (assignment) {
            _this.selectedDrivers.push(assignment.driver.id);
        });
        this.openJobEventPanel.emit(row);
    };
    DispatchScheduleTableComponent.prototype.editDriver = function (row) {
        this.drivers.map(function (driver) {
            row.selected = (driver.id === row.id);
            return row;
        });
        this.selectedDrivers = [row.id];
        this.openDriverPanel.emit(row);
    };
    /**
     * Evaluates the assignments on a driver and figures out whether it has been fully or incompletely dispatched
     *
     * @param {DispatchDriver} row The input driver data
     * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
     */
    DispatchScheduleTableComponent.prototype.driverDispatchedStatus = function (row) {
        var status = 'unassigned';
        if (row.assignments.length) {
            status = row.assignments.map(function (a) { return (a.dispatched); }).findIndex(function (dispatched) { return (!dispatched); }) > -1 ? 'dispatched incomplete' : 'dispatched';
        }
        return status;
    };
    /**
     * Evaluates the assignments on a jobEvent and figures out whether it has been fully or incompletely dispatched
     *
     * @param {DispatchJobEvent} row The input jobEvent data
     * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
     */
    DispatchScheduleTableComponent.prototype.jobEventDispatchedStatus = function (row) {
        var status = 'unassigned';
        if (row.assignments.length) {
            status = row.assignments.map(function (a) { return (a.dispatched); }).findIndex(function (dispatched) { return (!dispatched); }) > -1 ? 'dispatched incomplete' : 'dispatched';
        }
        return status;
    };
    /**
     * Evaluates the shares on a jobEvent and figures out whether it has been fully or incompletely dispatched
     *
     * @param {DispatchJobEvent} row The input jobEvent data
     * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
     */
    DispatchScheduleTableComponent.prototype.shareDispatchedStatus = function (row) {
        var status = 'unassigned';
        if (row.shares.length) {
            status = row.shares.map(function (a) { return (a.dispatched >= a.numTrucks); }).findIndex(function (dispatched) { return (!dispatched); }) > -1 ? 'dispatched incomplete' : 'dispatched';
        }
        return status;
    };
    DispatchScheduleTableComponent.prototype.select = function (row) {
        var _this = this;
        row.selected = !row.selected;
        if (row.selected) {
            row.assignments.forEach(function (assignment) {
                _this.selectedDrivers.push(assignment.driver.id);
            });
        }
        else {
            row.assignments.forEach(function (assignment) {
                _this.selectedDrivers = _this.selectedDrivers.filter(function (id) { return (id !== assignment.driver.id); });
            });
        }
    };
    DispatchScheduleTableComponent.prototype.selectAll = function (e, type) {
        var _this = this;
        this.allSelected = e.checked;
        if (type === 'jobEvents') {
            this.jobEvents.map(function (row) {
                row.selected = _this.allSelected;
                if (_this.allSelected) {
                    row.assignments.forEach(function (assignment) {
                        _this.selectedDrivers.push(assignment.driver.id);
                    });
                }
                else {
                    _this.selectedDrivers = [];
                }
                return row;
            });
        }
        else {
            this.drivers.map(function (row) {
                row.selected = _this.allSelected;
                _this.selectedJobEvents = [];
                return row;
            });
        }
    };
    DispatchScheduleTableComponent.prototype.searchJobEvents = function (e) {
        if (e) {
            this.jobEventSearch = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            e = e.toUpperCase();
            this.displayedJobEvents = this.jobEvents.filter(function (j) { return ((j.jobName && j.jobName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
                (j.orderNumber && j.orderNumber.toUpperCase().includes(e))); });
        }
        else {
            this.displayedJobEvents = this.jobEvents;
        }
        this.applyTagsAndSetTableData();
    };
    DispatchScheduleTableComponent.prototype.searchDrivers = function (e) {
        if (e) {
            this.driverSearch = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            e = e.toUpperCase();
            this.displayedDrivers = this.drivers.filter(function (driver) { return ((driver.name && driver.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
                (driver.truck && driver.truck.name.toUpperCase().includes(e)) ||
                driver.assignments.map(function (a) { return (a.jobevent && a.jobevent.job && a.jobevent.job.orderNumber && a.jobevent.job.orderNumber.toUpperCase()); }).join(' ').includes(e) ||
                driver.assignments.map(function (a) { return (a.jobevent && a.jobevent.job &&
                    a.jobevent.job.name && a.jobevent.job.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()); }).join(' ').includes(e)); });
        }
        else {
            this.displayedDrivers = this.drivers;
        }
        this.setTablePage('driver');
    };
    DispatchScheduleTableComponent.prototype.sort = function (sortEvent, table) {
        var _this = this;
        switch (sortEvent.active) {
            case 'job':
                this[table + 'SortKey'] = 'jobName';
                break;
            case 'status':
                this[table + 'SortKey'] = 'status';
                break;
            case 'customer-name':
                this[table + 'SortKey'] = 'customer';
                break;
            case 'start-time':
                this[table + 'SortKey'] = 'shift1Start';
                break;
            case 'end-time':
                this[table + 'SortKey'] = 'shift1End';
                break;
            case 'amount-ordered':
                this[table + 'SortKey'] = 'dailyDeliveryTarget';
                break;
            case 'total-ordered':
                this[table + 'SortKey'] = 'stats.totalOrdered';
                break;
            case 'loads-assigned':
                this[table + 'SortKey'] = 'assignedLoads';
                break;
            case 'trucks-requested':
                this[table + 'SortKey'] = 'numTrucks';
                break;
            case 'driver':
                this[table + 'SortKey'] = 'name';
                break;
            case 'assigned-trucks':
                this[table + 'SortKey'] = 'assignments.length';
                break;
            case 'assigned-loads':
                this[table + 'SortKey'] = 'loadCount';
                break;
            case 'collaborator-actions':
                this[table + 'SortKey'] = 'shares.length';
                break;
            default:
                this[table + 'SortKey'] = sortEvent.active.replace(/([-_][a-z])/g, function (x) { return x.toUpperCase().replace('-', ''); });
                break;
        }
        this[table + 'SortDirection'] = sortEvent.direction;
        this['displayed' + table.charAt(0).toUpperCase() + table.slice(1) + 's'] = this['displayed' + table.charAt(0).toUpperCase() + table.slice(1) + 's'].sort(function (a, b) {
            if (sortEvent.active === 'amount-ordered') {
                return _this[table + 'SortDirection'] === 'asc' ?
                    (a[_this[table + 'SortKey']] - b[_this[table + 'SortKey']]) :
                    (b[_this[table + 'SortKey']] - a[_this[table + 'SortKey']]);
            }
            else {
                return _this[table + 'SortDirection'] === 'asc' ?
                    sortEvent.active === 'assigned-trucks' ?
                        ((a.assignments.length < b.assignments.length) ? -1 : (a.assignments.length > b.assignments.length) ? 1 : 0) :
                        ((a[_this[table + 'SortKey']] < b[_this[table + 'SortKey']]) ? -1 :
                            (a[_this[table + 'SortKey']] > b[_this[table + 'SortKey']]) ? 1 : 0) :
                    sortEvent.active === 'assigned-trucks' ?
                        ((a.assignments.length > b.assignments.length) ? -1 : (a.assignments.length < b.assignments.length) ? 1 : 0) :
                        ((a[_this[table + 'SortKey']] > b[_this[table + 'SortKey']]) ? -1 :
                            (a[_this[table + 'SortKey']] < b[_this[table + 'SortKey']]) ? 1 : 0);
            }
        });
        this.setTablePage(table);
    };
    DispatchScheduleTableComponent.prototype.setTablePage = function (table, e, save) {
        if (save === void 0) { save = false; }
        var startPos;
        if (e) {
            this[table + 'PageSize'] = e.pageSize;
            startPos = e.pageIndex * e.pageSize;
        }
        else {
            startPos = 0;
            if (this[table + 'PageSize'] === 100) {
                this[table + 'PageSize'] = 10;
            }
            if (this.jobEventPaginator) {
                this.jobEventPaginator.firstPage();
            }
            if (this.driverPaginator) {
                this.driverPaginator.firstPage();
            }
        }
        if (table === 'jobEvent' && this.displayedJobEvents) {
            this.jobEventsDataSource.data = this.displayedJobEvents.slice(startPos, (startPos + this.jobEventPageSize));
        }
        else if (table === 'driver' && this.displayedDrivers) {
            this.driversDataSource.data = this.displayedDrivers.slice(startPos, (startPos + this.driverPageSize));
        }
        if (save) {
            this.savePreferences();
        }
    };
    DispatchScheduleTableComponent.prototype.setSelectedAction = function (option, row) {
        switch (option) {
            case 'Edit Job':
                this.editJob(row);
                break;
            case 'Edit Job Days':
                this.editJobDays(row);
                break;
            case 'Clone Job':
                this.cloneJob(row);
                break;
            case 'Edit Day Details':
                this.editDayDetails(row);
                break;
            case 'Auto-Assign Previous Trucks & Drivers':
                this.autoAssign(row);
                break;
            case 'Move to a New Day':
                this.modifyAssignments(row, 'move');
                break;
            case 'Copy to a New Day':
                this.modifyAssignments(row, 'copy');
                break;
            case 'Remove Assignments':
                this.removeAssignments(row);
                break;
            case 'Cancel Job':
                this.cancelJobs(row);
                break;
            case 'Edit Collaborators':
                this.openEditCollaborators(row);
                break;
            case 'Send Notes to Collaborators':
                this.openSendNotes(row);
                break;
            case 'Add Collaborators':
                this.navigateToAddCollaborator(row);
                break;
        }
    };
    DispatchScheduleTableComponent.prototype.addNewJob = function () {
        this.router.navigate(['/jobs/new'], {
            queryParams: {
                returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
            }
        });
    };
    DispatchScheduleTableComponent.prototype.editJob = function (jobEvent) {
        this.router.navigate(['/jobs/' + jobEvent.jobId + '/edit'], {
            queryParams: {
                returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
            }
        });
    };
    DispatchScheduleTableComponent.prototype.editJobDays = function (jobEvent) {
        var _this = this;
        var dialog = this.dialog.open(edit_job_dialog_component_1.EditJobDialogComponent, {
            width: '430px',
            data: { jobId: jobEvent.jobId }
        });
        dialog.componentInstance.callback = function () { return _this.getJobEvents.emit(); };
    };
    DispatchScheduleTableComponent.prototype.cloneJob = function (jobEvent) {
        this.router.navigate(['/jobs/clone/' + jobEvent.jobId], {
            queryParams: {
                returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
            }
        });
    };
    DispatchScheduleTableComponent.prototype.editDayDetails = function (jobEvent) {
        var _this = this;
        this.jobEventService.getJobEvent(jobEvent.id).subscribe(function (res) {
            var dialog = _this.dialog.open(edit_job_event_dialog_component_1.EditJobEventDialogComponent, {
                width: '430px'
            });
            if (dialog) {
                dialog.componentInstance.jobEvent = res;
                dialog.componentInstance.callback = function () { return _this.getJobEvents.emit(); };
            }
        }, function (err) { return _this.errors.emit(err); });
    };
    DispatchScheduleTableComponent.prototype.autoAssign = function (jobEvent) {
        var _this = this;
        this.jobEventService.autoAssign(jobEvent.id).subscribe(function (res) {
            _this.getAssignments.emit(_this.drivers);
        }, function (err) {
            var results = JSON.parse(err._body);
            results = camelcaseKeysDeep(results);
            var dialog = _this.dialog.open(auto_assign_conflict_dialog_component_1.AutoAssignConflictDialogComponent, {
                width: '430px'
            });
            dialog.componentInstance.results = results;
            dialog.componentInstance.callback = function () { return _this.getAssignments.emit(_this.drivers); };
        });
    };
    DispatchScheduleTableComponent.prototype.modifyAssignments = function (jobEvent, action) {
        var _this = this;
        var dialog = this.dialog.open(move_assignments_dialog_component_1.MoveAssignmentsDialogComponent, { width: '850px' });
        if (dialog) {
            var convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
            dialog.componentInstance.copy = action === 'copy';
            dialog.componentInstance.job = convertedJobEvent.job;
            dialog.componentInstance.start = moment(this.selectedDate).startOf('day').toDate();
            dialog.componentInstance.jobEvent = convertedJobEvent;
            dialog.componentInstance.assignments = jobEvent.assignments;
            dialog.componentInstance.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
            dialog.componentInstance.callback = function () { return _this.getAssignments.emit(_this.drivers); };
        }
    };
    DispatchScheduleTableComponent.prototype.removeAssignments = function (row) {
        var _this = this;
        var assignmentIds = row.assignments.map(function (assignment) { return (assignment.id); });
        if (assignmentIds && assignmentIds.length) {
            this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
                width: '430px',
                height: '250px'
            });
            this.confirmDialog.componentInstance.attributes = {
                title: "Remove " + assignmentIds.length + " Assignments?",
                body: "This action will remove the selected assignments from this day.",
                close: 'Cancel',
                accept: 'Remove'
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.assignmentService.bulkRemove(assignmentIds).subscribe(function () {
                        _this.getAssignments.emit(_this.drivers);
                    }, function (err) { return _this.errors.emit(err); });
                }
                _this.confirmDialog = null;
            }, function (err) { return _this.errors.emit(err); });
        }
    };
    DispatchScheduleTableComponent.prototype.getTags = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        var request = this.tagService.list(query);
        if (next) {
            request = this.tagService.listNext();
        }
        else {
            this.tags = [];
        }
        if (request) {
            this.tagsReq = request.subscribe(function (res) {
                _this.tags.push.apply(_this.tags, res);
            }, function (err) { return _this.errors.emit(err); });
        }
    };
    DispatchScheduleTableComponent.prototype.getTruckTypes = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
            this.truckTypesReq.unsubscribe();
        }
        var request = this.truckTypeService.list(query);
        if (next) {
            request = this.truckTypeService.listNext();
        }
        else {
            this.truckTypes = [];
        }
        if (request) {
            this.truckTypesReq = request.subscribe(function (res) {
                _this.truckTypes.push.apply(_this.truckTypes, res);
            }, function (err) { return _this.errors.emit(err); });
        }
    };
    DispatchScheduleTableComponent.prototype.selectTags = function (tags) {
        if (this.tagsDropdown) {
            this.tagsDropdown.selectedItems = tags;
            this.tagsDropdown.setButtonText();
            if (tags && tags.length > 0) {
                this.tagsUrlList = tags.map(function (tag) { return tag.name; }).join(',') + ',';
            }
            else {
                this.tagsUrlList = null;
            }
            this.driverQuery.emit({ tags: this.tagsUrlList });
        }
    };
    DispatchScheduleTableComponent.prototype.selectJobEventTags = function (tags) {
        if (this.jobEventTagsDropdown) {
            this.jobEventTagsDropdown.selectedItems = tags;
            this.jobEventTagsDropdown.setButtonText();
            this.setJobEventAppliedFilters('tags', tags);
        }
        this.searchJobEvents(this.jobEventSearch);
    };
    Object.defineProperty(DispatchScheduleTableComponent.prototype, "jobEventTagsSelected", {
        get: function () {
            var tagsSelected = this.jobEventAppliedFilters && lodash_1.find(this.jobEventAppliedFilters, { key: 'tags' });
            return tagsSelected ? tagsSelected.values : [];
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleTableComponent.prototype.selectJobEventTruckType = function (truckTypes) {
        if (this.jobEventTruckTypeDropdown) {
            this.jobEventTruckTypeDropdown.selectedItems = truckTypes;
            this.jobEventTruckTypeDropdown.setButtonText();
            this.setJobEventAppliedFilters('truckTypes', truckTypes);
        }
        if (truckTypes && truckTypes.length > 0) {
            this.truckTypesUrlList = truckTypes.map(function (type) { return type.id; }).join(',');
        }
        else {
            this.truckTypesUrlList = null;
        }
        this.getJobEvents.emit({ truck_types_in: this.truckTypesUrlList });
    };
    DispatchScheduleTableComponent.prototype.setJobEventAppliedFilters = function (key, value) {
        var filterIdx = this.jobEventAppliedFilters.findIndex(function (f) { return f.key === key; });
        if (value && value.length) {
            if (filterIdx < 0) {
                var newFilter = new filter_option_1.FilterOption({
                    key: key,
                    values: value
                });
                this.jobEventAppliedFilters.push(newFilter);
            }
            else {
                this.jobEventAppliedFilters.forEach(function (filter) {
                    if (filter.key === key) {
                        filter.values = value;
                    }
                });
            }
        }
        else {
            var filterIndex = this.jobEventAppliedFilters.findIndex(function (f) { return f.key === key; });
            if (filterIndex > -1) {
                this.jobEventAppliedFilters.splice(filterIndex, 1);
            }
        }
        this.savePreferences(this.jobEventAppliedFilters);
    };
    DispatchScheduleTableComponent.prototype.expandSearch = function () {
        if (this.jobEventTagsDropdown && this.jobEventTagsDropdown.selectedItems && this.jobEventTagsDropdown.selectedItems.length) {
            this.selectJobEventTags([]);
        }
        if (this.jobEventTruckTypeDropdown &&
            this.jobEventTruckTypeDropdown.selectedItems &&
            this.jobEventTruckTypeDropdown.selectedItems.length) {
            this.selectJobEventTruckType([]);
        }
        if (this.jobEventSearch) {
            this.jobEventSearch = '';
            this.searchJobEvents('');
        }
    };
    DispatchScheduleTableComponent.prototype.applyTagsAndSetTableData = function () {
        if (this.jobEventTagsDropdown && this.jobEventTagsDropdown.selectedItems && this.jobEventTagsDropdown.selectedItems.length) {
            var tagIds_1 = this.jobEventTagsDropdown.selectedItems.map(function (tag) { return tag.id; });
            this.displayedJobEvents = this.displayedJobEvents.filter(function (j) { return (j.tags && j.tags.filter(function (tag) { return (tagIds_1.findIndex(function (id) { return (id === tag.id); })) > -1; }).length > 0); });
        }
        this.setTablePage('jobEvent');
    };
    DispatchScheduleTableComponent.prototype.updateJobEvent = function (field, value, jobEvent) {
        var _this = this;
        if (field === 'shift1Start' && jobEvent.shift1Start) {
            var shift1StartDate = moment(jobEvent.shift1Start).toDate().toLocaleDateString();
            jobEvent[field] = moment(shift1StartDate + " " + value, 'MM/DD/YYYY HH:mm').toISOString();
        }
        else if (field === 'shift1End' && jobEvent.shift1End) {
            var shift1EndDate = moment(jobEvent.shift1End).toDate().toLocaleDateString();
            jobEvent[field] = moment(shift1EndDate + " " + value, 'MM/DD/YYYY HH:mm').toISOString();
        }
        else if (field === 'numTrucks') {
            // dev note: once jobEvent is given a serializer move this to that method
            jobEvent[field] = Number(value);
        }
        else {
            jobEvent[field] = value;
        }
        // dev note: delete this once BE resolves the 'valid interger' errors for these fields
        var convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
        if (field !== 'deliveryInterval') {
            delete convertedJobEvent.deliveryInterval;
        }
        else if (value.length === 0) {
            convertedJobEvent.deliveryInterval = '0';
        }
        if (field !== 'dailyDeliveryTarget') {
            delete convertedJobEvent.dailyDeliveryTarget;
        }
        else if (value.length === 0) {
            convertedJobEvent.dailyDeliveryTarget = '0';
        }
        field === 'orderNumber' ? this.jobService.save({ id: convertedJobEvent.job.id, orderNumber: value }).subscribe(function () { }, function (err) { return _this.errors.emit(err); }) :
            this.jobEventService.save(convertedJobEvent).subscribe(function () { return _this.updateStats.emit(_this.jobEvents); }, function (err) { return _this.errors.emit(err); });
        field === 'jobNumber' ? this.jobService.save({ id: convertedJobEvent.job.id, jobNumber: value }).subscribe(function () { }, function (err) { return _this.errors.emit(err); }) :
            this.jobEventService.save(convertedJobEvent).subscribe(function () { return _this.updateStats.emit(_this.jobEvents); }, function (err) { return _this.errors.emit(err); });
    };
    DispatchScheduleTableComponent.prototype.dispatch = function () {
        var _this = this;
        var dispatchList = [];
        this.jobEvents.forEach(function (jobEvent) {
            if (jobEvent.selected && jobEvent.assignments && jobEvent.assignments.length > 0) {
                dispatchList.push(_this.dispatchService.save({
                    jobEvent: jobEvent.id,
                    notify_new: true
                }));
            }
        });
        rxjs_1.forkJoin(dispatchList).subscribe(function (updatedJobEvents) {
            updatedJobEvents.forEach(function (jobEvent) {
                _this.jobEvents[_this.jobEvents.findIndex(function (j) { return (j.id === jobEvent.id); })] = Object.assign(_this.jobEvents[_this.jobEvents.findIndex(function (j) { return (j.id === jobEvent.id); })], {
                    lastDispatchedTime: jobEvent.lastDispatchedTime,
                    assignments: _this.jobEvents[_this.jobEvents.findIndex(function (j) { return (j.id === jobEvent.id); })].assignments.map(function (a) { return (Object.assign(a, { dispatched: true })); })
                });
            });
        }, function (err) { return _this.errors.emit(err); });
    };
    DispatchScheduleTableComponent.prototype.arrangeAssignment = function (driver, index, order) {
        var _this = this;
        var _a, _b;
        var startTime1, startTime2;
        if (order === 'next' && driver.assignments && ((index + 1) < driver.assignments.length)) {
            startTime1 = driver.assignments[index].uniqueStart;
            startTime2 = driver.assignments[index + 1].uniqueStart;
            driver.assignments[index].uniqueStart = startTime2;
            driver.assignments[index + 1].uniqueStart = startTime1;
            _a = [driver.assignments[index + 1], driver.assignments[index]], driver.assignments[index] = _a[0], driver.assignments[index + 1] = _a[1];
        }
        else if (order === 'previous' && index > 0) {
            startTime1 = driver.assignments[index - 1].uniqueStart;
            startTime2 = driver.assignments[index].uniqueStart;
            driver.assignments[index - 1].uniqueStart = startTime2;
            driver.assignments[index].uniqueStart = startTime1;
            _b = [driver.assignments[index], driver.assignments[index - 1]], driver.assignments[index - 1] = _b[0], driver.assignments[index] = _b[1];
        }
        if (startTime1 && startTime2) {
            this.assignmentService.bulkUpdate(driver.assignments.map(function (a) { return ({ id: a.id, uniqueStart: a.uniqueStart }); })).subscribe(function (bulkRes) {
                if (bulkRes.errors) {
                    _this.errors.emit(bulkRes.errors);
                }
            });
        }
    };
    DispatchScheduleTableComponent.prototype.changeTruck = function (driver, truck) {
        var _this = this;
        if (!driver.truck || (driver.truck.id !== truck.id)) {
            if (driver.assignments) {
                driver.assignments = driver.assignments.map(function (a) { return (Object.assign(a, { truck: truck })); });
                this.assignmentService.bulkUpdate(driver.assignments).subscribe(function (bulkRes) {
                    if (bulkRes.errors) {
                        _this.errors.emit(bulkRes.errors);
                    }
                });
                driver.truck = truck;
                driver.assignments = driver.assignments.map(function (a) { return (Object.assign(a, { truck: truck })); });
                this.driverService.save({ id: driver.id, truck: truck }).subscribe(function (res) {
                    _this.assignmentService.bulkUpdate(driver.assignments).subscribe(function (bulkRes) {
                        if (bulkRes.errors) {
                            _this.errors.emit(bulkRes.errors);
                        }
                    });
                }, function (err) { return _this.errors.emit(err); });
            }
        }
    };
    DispatchScheduleTableComponent.prototype.cancelJobs = function (jobEvent) {
        var _this = this;
        var jobsSelected = this.jobEvents.filter(function (j) { return (j.selected); });
        if (jobEvent && !jobEvent.cancelled && jobEvent.canEdit) {
            var dialog = this.dialog.open(cancel_job_dialog_component_1.CancelJobDialogComponent, {
                width: '430px',
                data: { jobEventIds: [jobEvent.id] }
            });
            dialog.componentInstance.callback = function () { return _this.getJobEvents.emit(); };
        }
        else if (!jobEvent && jobsSelected.length) {
            var dialog = this.dialog.open(cancel_job_dialog_component_1.CancelJobDialogComponent, {
                width: '430px',
                data: {
                    jobEventIds: jobsSelected.map(function (j) { return (j.id); }),
                    allJobEventsCount: jobsSelected.length,
                    jobEventsDate: this.selectedDate
                }
            });
            dialog.componentInstance.callback = function () { return _this.getJobEvents.emit(); };
        }
    };
    DispatchScheduleTableComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'cancel':
                this.cancelJobs();
                break;
        }
    };
    /**
     * Opens the add collaborator dialog for a specified jobevent and preselect
     * the currently set selectedConnections
     *
     * @param {DispatchJobEvent} jobEvent The selected jobEvent
     */
    DispatchScheduleTableComponent.prototype.openAddCollaborators = function (jobEvent) {
        var _this = this;
        var dialog = this.dialog.open(add_collaborators_dialog_component_1.AddCollaboratorsDialogComponent, {
            width: '1100px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.selectedConnections = this.selectedConnections;
            dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
            dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
            dialog.componentInstance.callback = function () { return _this.getShares.emit(); };
        }
    };
    /**
     * Opens the edit collaborators dialog for a specified jobevent
     *
     * @param {DispatchJobEvent} jobEvent The selected jobEvent
     */
    DispatchScheduleTableComponent.prototype.openEditCollaborators = function (jobEvent) {
        var _this = this;
        var dialog = this.dialog.open(edit_collaborators_dialog_component_1.EditCollaboratorsDialogComponent, {
            width: '790px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
            dialog.componentInstance.allSelected = true;
            dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
            dialog.componentInstance.callback = function () { return _this.getShares.emit(); };
        }
    };
    /**
     * Opens the send notes dialog for a specified jobevent
     *
     * @param {DispatchJobEvent} jobEvent The selected jobEvent
     */
    DispatchScheduleTableComponent.prototype.openSendNotes = function (jobEvent) {
        var dialog = this.dialog.open(send_collaborator_notes_dialog_component_1.SendCollaboratorNotesDialogComponent, {
            width: '790px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
            dialog.componentInstance.allSelected = true;
        }
    };
    DispatchScheduleTableComponent.prototype.onColumnChange = function () {
        this.savePreferences();
    };
    /**
     * Gets any available custom fields for the currently logged in org and
     * set the brokerRateCodeKey for sharing any jobEvents
     *
     */
    DispatchScheduleTableComponent.prototype.getCustomFields = function () {
        var _this = this;
        this.customFieldService.list({
            kind: custom_field_1.CustomFieldKind.Share,
            display_name: 'Broker Rate Code',
            active: 'True'
        }).subscribe(function (fields) {
            _this.brokerRateCodeKey = fields && fields[0] && fields[0].key;
        });
    };
    DispatchScheduleTableComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        this.preferenceService.list({
            name: this.view === 'jobEvents' ? this.jobEventsPreferenceKey : this.driversPreferenceKey,
            type: 'user',
            profile: currentUser.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                _this.parsePreferences();
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    DispatchScheduleTableComponent.prototype.savePreferences = function (filters) {
        var _this = this;
        var currentUser = this.authenticationService.user();
        var jobEvents = this.preference &&
            this.preference.blob &&
            this.preference.blob.columns &&
            this.preference.blob.columns.jobEvents ? this.preference.blob.columns.jobEvents : null;
        var drivers = this.preference &&
            this.preference.blob &&
            this.preference.blob.columns &&
            this.preference.blob.columns.drivers ? this.preference.blob.columns.drivers : null;
        this.preference = __assign({}, this.preference, { name: this.view === 'jobEvents' ? this.jobEventsPreferenceKey : this.driversPreferenceKey, type: 'user', profile: currentUser.id, blob: {
                columns: {},
                pageSize: this.view === 'jobEvents' ? this.jobEventPageSize : this.driverPageSize,
            } });
        if (this.view === 'jobEvents') {
            this.preference.blob.columns.jobEvents = {
                loadsOrdered: this.getColumnPreference('Loads Ordered', this.displayedJobEventColumns, jobEvents, 'loadsOrdered', this.loadsOrderedDefaultColumns),
                tonsOrdered: this.getColumnPreference('Tons Ordered', this.displayedJobEventColumns, jobEvents, 'tonsOrdered', this.tonsOrderedDefaultColumns),
                trucksRequested: this.getColumnPreference('Trucks Requested', this.displayedJobEventColumns, jobEvents, 'trucksRequested', this.trucksRequestedDefaultColumns),
                truckTypeRequested: this.getColumnPreference('Truck Type Requested', this.displayedJobEventColumns, jobEvents, 'truckTypeRequested', this.truckTypeRequestedDefaultColumns)
            };
        }
        else {
            this.preference.blob.columns.drivers = {
                loadsOrdered: this.getColumnPreference('Loads Ordered', this.displayedDriverColumns, drivers, 'loadsOrdered', this.driverDefaultColumns),
                tonsOrdered: this.getColumnPreference('Tons Ordered', this.displayedDriverColumns, drivers, 'tonsOrdered', this.driverDefaultColumns),
                trucksRequested: this.getColumnPreference('Trucks Requested', this.displayedDriverColumns, drivers, 'trucksRequested', this.driverDefaultColumns)
            };
        }
        if (filters) {
            this.preference.blob.filters = (this.preference.blob.filters || []).concat(filters);
        }
        this.preferenceService.save(this.preference).subscribe(function (preference) {
            _this.preference = preference;
        });
    };
    DispatchScheduleTableComponent.prototype.getColumnPreference = function (loadType, displayedColumns, storedPreference, storedPreferenceKey, defaultColumns) {
        return this.activeLoadType === loadType ? displayedColumns :
            storedPreference && storedPreference[storedPreferenceKey] ? storedPreference[storedPreferenceKey] :
                defaultColumns;
    };
    DispatchScheduleTableComponent.prototype.parsePreferences = function () {
        var _this = this;
        if (!this.preference || !this.preference.blob) {
            return;
        }
        if (this.preference.blob['columns']) {
            var displayedColumns = this.view === 'jobEvents' ? this.displayedJobEventColumns : this.displayedDriverColumns;
            var availableColumns = this.view === 'jobEvents' ? this.availableJobEventsColumns : this.availableDriverColumns;
            var currentLoadType = '';
            if (this.activeLoadType === 'Loads Ordered') {
                currentLoadType = 'loadsOrdered';
            }
            if (this.activeLoadType === 'Tons Ordered') {
                currentLoadType = 'tonsOrdered';
            }
            if (this.activeLoadType === 'Trucks Requested') {
                currentLoadType = 'trucksRequested';
            }
            if (this.activeLoadType === 'Truck Type Requested') {
                currentLoadType = 'truckTypeRequested';
            }
            var columns = this.preference &&
                this.preference.blob &&
                this.preference.blob.columns &&
                this.preference.blob.columns[this.view] &&
                this.preference.blob.columns[this.view][currentLoadType] ? this.preference.blob.columns[this.view][currentLoadType] : null;
            if (columns) {
                columns = lodash_1.intersectionWith(columns, availableColumns, function (col, c) { return col === c.key; });
                if (this.view === 'jobEvents') {
                    var updatesIdx = columns.findIndex(function (c) { return c === 'updates'; });
                    if (updatesIdx === -1) {
                        columns.splice(1, 0, 'updates');
                    }
                    this.displayedJobEventColumns = columns;
                }
                else {
                    this.displayedDriverColumns = columns;
                }
                displayedColumns = columns;
            }
        }
        if (this.preference.blob['pageSize']) {
            var pageSize = this.preference.blob['pageSize'];
            if (this.view === 'jobEvents') {
                this.jobEventPageSize = pageSize;
            }
            if (this.view === 'drivers') {
                this.driverPageSize = pageSize;
            }
        }
        var filters = this.preference.blob['filters'] || [];
        if (filters && filters.length) {
            if (this.view === 'jobEvents') {
                filters = filters.filter(function (field) { return !field['default']; });
                filters.forEach(function (f) {
                    var filterIndex = lodash_1.findIndex(_this.jobEventAppliedFilters, {
                        key: f.key,
                        default: true,
                    });
                    if (filterIndex !== -1) {
                        _this.jobEventAppliedFilters[filterIndex] = f;
                    }
                });
                this.jobEventAppliedFilters = lodash_1.uniqBy(this.jobEventAppliedFilters.concat(filters), 'key');
            }
        }
        this.initPrefs = true;
    };
    DispatchScheduleTableComponent.prototype.navigateToAddCollaborator = function (jobEvent) {
        var newRelativeUrl = this.router.createUrlTree(['/jobs', jobEvent.jobId, jobEvent.id, 'collaborators']);
        var baseUrl = window.location.href.replace(this.router.url, '');
        window.open(baseUrl + newRelativeUrl, '_blank');
    };
    DispatchScheduleTableComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    DispatchScheduleTableComponent.prototype.removeUpdateNotification = function (row) {
        row['updates'] = false;
        var updates = this.jobEvents.findIndex(function (j) { return j['updates']; });
        if (updates === -1) {
            this.jobEventUpdates = false;
        }
    };
    DispatchScheduleTableComponent.prototype.removeUpdateNotifications = function () {
        this.jobEvents.forEach(function (jobEvent) {
            if (jobEvent['updates']) {
                jobEvent['updates'] = false;
            }
        });
        this.jobEventUpdates = false;
    };
    DispatchScheduleTableComponent.prototype.checkIfJobEventsHaveUpdates = function () {
        this.jobEventUpdates = false;
        if (this.jobEvents.length) {
            var updates = this.jobEvents.findIndex(function (j) { return j['updates']; });
            if (updates > -1) {
                this.jobEventUpdates = true;
            }
        }
    };
    return DispatchScheduleTableComponent;
}());
exports.DispatchScheduleTableComponent = DispatchScheduleTableComponent;
